const container = document.querySelector('.scrollbardiv');

function addScrollBar() {
  container.classList.add('scrollbar');
  container.classList.remove('noscrollbar');
}

function removeScrollBar() {
  container.classList.remove('scrollbar');
  container.classList.add('noscrollbar');
}

let isScroll = false;

container.addEventListener('scroll', function () {
  isScroll = true;
  const currentPosition = window.pageYOffset;
  setTimeout(function () {
    const newPosition = window.pageYOffset;
    if (currentPosition === newPosition) {
      isScroll = false;
    }
  }, 1000);
});

let isBorder = false;

window.addEventListener('mousemove', function (e) {
  const x = e.clientX;
  const w = window.innerWidth;
  if (x >= w - 12) {
    isBorder = true;
  } else {
    isBorder = false;
  }
});

setInterval(function () {
  if (isBorder === true || isScroll === true) {
    addScrollBar();
  } else {
    removeScrollBar();
  }
}, 100);
