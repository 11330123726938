export const schema = {
  models: {
    LandingPage: {
      name: 'LandingPage',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        code: {
          name: 'code',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        config: {
          name: 'config',
          isArray: false,
          type: 'AWSJSON',
          isRequired: true,
          attributes: [],
        },
        template: {
          name: 'template',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        casinoID: {
          name: 'casinoID',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        casino: {
          name: 'casino',
          isArray: false,
          type: {
            model: 'Casino',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['casinoID'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'LandingPages',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byCasino',
            fields: ['casinoID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                provider: 'userPools',
                ownerField: 'owner',
                allow: 'owner',
                identityClaim: 'cognito:username',
                operations: ['create', 'update', 'delete', 'read'],
              },
              {
                allow: 'private',
                operations: ['read', 'update'],
              },
              {
                allow: 'private',
                provider: 'iam',
                operations: ['read', 'update'],
              },
            ],
          },
        },
      ],
    },
    Casino: {
      name: 'Casino',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        code: {
          name: 'code',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        git: {
          name: 'git',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        assetsUrl: {
          name: 'assetsUrl',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        landingPages: {
          name: 'landingPages',
          isArray: true,
          type: {
            model: 'LandingPage',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['casino'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'Casinos',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                provider: 'userPools',
                ownerField: 'owner',
                allow: 'owner',
                identityClaim: 'cognito:username',
                operations: ['create', 'update', 'delete', 'read'],
              },
              {
                allow: 'private',
                operations: ['read'],
              },
              {
                allow: 'private',
                provider: 'iam',
                operations: ['read'],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {},
  nonModels: {},
  codegenVersion: '3.4.4',
  version: '9f727854c3e460a9c7006973d088e220',
};
