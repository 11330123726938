import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import Banner from '../components/Banner';
import { useEffect, type CSSProperties } from 'react';
import { SectionTitle } from '../components/SectionTitle';
import { useBrandsContext } from './brands/context';

function Banners() {
  const { brand } = useBrandsContext();
  useEffect(() => {
    document.title = `${brand.name} | Banners`;
  }, [brand.name]);
  return (
    <section style={styles.section}>
      <SectionTitle title="Banners" />
      <Stack spacing={1}>
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem' }}
        />
        <Skeleton
          variant="rectangular"
          width={210}
          height={118}
        />
        <Skeleton
          variant="rectangular"
          width={210}
          height={118}
        />
      </Stack>
      <Banner />
    </section>
  );
}

export default Banners;

const styles = {
  section: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  } as CSSProperties,
};
