import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import 'devextreme/dist/css/dx.dark.css';

import { LoggedAppProviders } from './LoggedApp';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Authenticator hideSignUp>
        <LoggedAppProviders />
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
