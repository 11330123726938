import { Outlet, useParams } from 'react-router-dom';
import NavBar from '../NavBar';
import Footer from './Footer';
import { useBrandsContext } from './brands/context';
import { useEffect } from 'react';
import Loading from '../components/Loading';

const Layout = () => {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;

export const BrandLayout = () => {
  const { brand, brands, changeBrand } = useBrandsContext();
  const params = useParams();

  useEffect(() => {
    const brand = brands.find((brand) => brand.code === params.brandCode);
    if (brand) {
      changeBrand(brand);
    }
  }, [brands, changeBrand, params.brandCode]);

  return !brand ? <Loading /> : <Outlet />;
};
