import { Component } from 'react';
import { Stack, Box, CircularProgress } from '@mui/material';

class Loading extends Component {
  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          spacing={1}
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        </Stack>
      </Box>
    );
  }
}

export default Loading;
