import type { CSSProperties } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';

import {
  Box,
  Modal,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  TextField,
  Alert,
} from '@mui/material';
import { Storage } from 'aws-amplify';

import { DataStore } from '@aws-amplify/datastore';
import { LandingPage } from '../models';
import Loading from '../components/Loading';

import DownloadLoadingSpinner from '../components/DownloadLoadingSpinner';
import { TextAreaField } from '@aws-amplify/ui-react';
import { useBrandsContext } from './brands/context';
import { SectionTitle } from '../components/SectionTitle';

const S3_DEFAULT_IDENTITY = 'eu-west-2:6f456f62-991a-4653-96ba-27111826d11c';

interface TemplateList<T> {
  [key: string]: T;
}

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

const LandingPagePage = (props: any) => {
  let { brand } = useBrandsContext();

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<TemplateList<any>>({});
  const [currentTemplate, setCurrentTemplate] = useState<any>(null);
  const [messageResponse, setMessageResponse] = React.useState<any>(null);
  const [lps, setLps] = useState<LandingPage[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (template: any) => {
    setCurrentTemplate(template);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const lpNameRef = useRef(null);
  const lpCodeRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const navigate = useNavigate();

  let templatesPath = `${brand.code}/lp-templates/`;

  function applySuggestion() {
    descriptionInputRef.current.value = messageResponse.correctedInput;
    createLp();
  }

  function createLp() {
    setMessageResponse(null);

    if (currentTemplate.ai) {
      API.post('apiLP', `/${brand.code}/web-component/create`, {
        headers: { accept: 'application/json' },
        body: {
          template: currentTemplate.code,
          config: {
            description: descriptionInputRef.current.value,
          },
        },
      })
        .then((response) => {
          setMessageResponse(response);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // @ts-ignore
      let name: string = lpNameRef.current.value;
      // @ts-ignore
      let code: string = lpCodeRef.current.value;

      // @ts-ignore
      DataStore.save(
        new LandingPage({
          name,
          code,
          casinoID: '',
          casino: brand,
          template: currentTemplate.code,
          config: '{}',
        })
      ).then((result) => {
        navigate(result.id);
      });
    }
  }

  async function downloadLp(lp: LandingPage) {
    document.getElementById('download-spinner').classList.add('active');
    let casino = await lp.casino;

    API.post('apiLP', `/landing-pages/${lp.id}/download`, {
      headers: { accept: 'application/zip, application/octet-stream' },
      body: {
        lp: lp.id,
        name: lp.name,
        code: lp.code,
        config: lp.config,
        brand: casino.code,
        template: lp.template,
        assetsUrl: '',
      },
    })
      .then((response) => {
        const decodedData = window.atob(response);
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        document.getElementById('download-spinner').classList.remove('active');

        downloadBlob(new Blob([uInt8Array], { type: 'application/zip' }), `${lp.code}.zip`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    document.title = `${brand.name} | Landing Pages`;
    setLoading(true);

    let templates: TemplateList<any> = {};

    Storage.list(templatesPath, { level: 'protected', identityId: S3_DEFAULT_IDENTITY })
      .then(async (result) => {
        await Promise.all(
          result.results.map(async (item) => {
            if (item.key === undefined) {
              return;
            }

            let itemName = item.key.substring(templatesPath.length);
            if (item == null || itemName === '' || itemName === '/' || itemName.slice(-1) !== '/') {
              return;
            }

            itemName = itemName.substring(0, itemName.length - 1);
            const res = await Storage.get(`${templatesPath + itemName}/index.json`, {
              download: true,
              level: 'protected',
              identityId: S3_DEFAULT_IDENTITY,
            });

            // @ts-ignore
            let config: any = await new Response(res.Body).json();

            templates[itemName] = {
              name: config.name,
              code: itemName,
              ai: config.ai ?? false,
              preview: await Storage.get(`${templatesPath + itemName}/preview.webp`, {
                level: 'protected',
                identityId: S3_DEFAULT_IDENTITY,
              }),
            };
          })
        );

        setTemplates(templates);
      })
      .then(async () => {
        let lps = await (
          await brand.landingPages.toArray()
        ).sort((lp1, lp2) => new Date(lp2.createdAt).getTime() - new Date(lp1.createdAt).getTime());

        setLps(lps);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  if (loading) {
    return <Loading />;
  }

  return (
    <section style={styles.section}>
      <DownloadLoadingSpinner />
      <SectionTitle title={`${brand.name} templates`} />
      <div style={styles.gridContainer}>
        {Object.entries(templates).map(([code, template]) => {
          return (
            <Card
              key={code}
              sx={{ maxWidth: 345, width: '100%' }}
            >
              <CardMedia
                component="img"
                image={template.preview}
                style={styles.cardMedia}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {template.name}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    handleOpen(template);
                  }}
                >
                  Copy
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </div>

      <SectionTitle title={`${brand.name} web contents`} />
      <div style={styles.gridContainer}>
        {lps.map((lp) => {
          return (
            <Card
              key={lp.id}
              sx={{ maxWidth: 345, width: '100%' }}
            >
              <Link to={lp.id}>
                <CardMedia
                  component="img"
                  image={`https://static.cms.cloud.digibeat.com/lps/${lp.code}/preview.webp`}
                  style={styles.cardMedia}
                  alt="green iguana"
                />
              </Link>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {lp.name}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={styles.landingPagenameText}
                >
                  {new Date(lp.createdAt).toLocaleString()}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    navigate(lp.id);
                  }}
                >
                  Modify
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    downloadLp(lp);
                  }}
                >
                  Download
                </Button>
              </CardActions>
            </Card>
          );
        })}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={styles.modal}
        >
          <Box sx={styles.createWebContent}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Create Web Content
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              Copy of {currentTemplate?.name}
            </Typography>

            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
            >
              {currentTemplate?.ai ? (
                <TextAreaField
                  ref={descriptionInputRef}
                  required
                  label="Description"
                  style={styles.descriptionTextArea}
                />
              ) : (
                <>
                  <TextField
                    inputRef={lpNameRef}
                    required
                    id="lpName"
                    label="Name"
                    variant="filled"
                    style={styles.textField}
                  />
                  <TextField
                    inputRef={lpCodeRef}
                    required
                    id="lpCode"
                    label="Code"
                    variant="filled"
                    style={styles.textField}
                  />
                </>
              )}
            </Box>

            {messageResponse ? (
              <Alert severity={messageResponse.messageType === 'ERROR' ? 'error' : 'info'}>
                {messageResponse.message}
                {messageResponse.messageType !== 'OK' && messageResponse.correctedInput !== '' ? (
                  <>
                    <br />
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={applySuggestion}
                    >
                      Apply suggestion
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Alert>
            ) : (
              ''
            )}
            <br />
            <Button
              variant="outlined"
              onClick={createLp}
            >
              Create
            </Button>
          </Box>
        </Modal>
      </div>
    </section>
  );
};

export default LandingPagePage;

const styles = {
  section: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  } as CSSProperties,
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'center',
  } as CSSProperties,
  createWebContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '25px',
    boxShadow: '24',
    p: 5,
  } as CSSProperties,
  cardMedia: { objectFit: 'cover', aspectRatio: 2 } as CSSProperties,
  landingPagenameText: { fontSize: '12px' } as CSSProperties,
  modal: { backdropFilter: 'blur(3px) saturate(1.5)' } as CSSProperties,
  descriptionTextArea: { width: '100%', color: 'white' } as CSSProperties,
  textField: { width: '100%' } as CSSProperties,
};
