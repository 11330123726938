/* eslint-disable max-len */
/* eslint-disable no-inline-styles/no-inline-styles */
import { Component } from 'react';

class Banner extends Component {
  render() {
    return (
      <div
        className="swiper-slide swiper-slide-prev carousel-item"
        style={{ display: 'flex', flex: '1 0 auto', width: '898px', position: 'relative' }}
      >
        <picture>
          <img
            alt=""
            src="bannerTest.png"
          />
        </picture>

        <div className="ta-promotion-item">
          <span style={{ top: '70px', left: '5px', fontSize: '14px', filter: 'blur(0.3px)' }}>
            <span style={{ fontSize: '30px', zIndex: '2' }}>UEFA Super Cup!</span>
            <span style={{ fontSize: '21px', zIndex: '2' }}>
              Wint Real Madrid deze
              <br />
              prijs voor de 5e keer?
            </span>
            <span
              style={{
                transform: 'rotate(-6deg)',
                background: 'linear-gradient(90deg,#ff914c,#ff328c) 0 0',
                width: 'fit-content',
                padding: '4px 13px',
                borderRadius: '4px',
              }}
            >
              Nu wordt het spanned
            </span>
          </span>
        </div>
        <div
          className="ta-promotion-item"
          style={{ bottom: '0', marginBottom: '5px' }}
        >
          <span style={{ fontSize: '11px', filter: 'blur(0.3px)', fontWeight: 'lighter' }}>
            <span style={{ zIndex: '2' }}>
              Quoteringen kunnen wijzigen
              <br />
              Wat kost gokken jou? Stop op tijd. 18+
            </span>
          </span>
        </div>
        <div className="ta-promotion-item">
          <div style={{ top: '15px', left: '405px', width: '80px', display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '80px' }}
              src="https://upload.wikimedia.org/wikipedia/en/thumb/5/56/Real_Madrid_CF.svg/1200px-Real_Madrid_CF.svg.png"
              alt=""
            />
            <span style={{ fontSize: '60px', fontWeight: '1000', margin: '0px 21px' }}>/</span>
            <img
              style={{ width: '80px', objectFit: 'contain' }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Eintracht_Frankfurt_Logo.svg/1200px-Eintracht_Frankfurt_Logo.svg.png"
              alt=""
            />
          </div>
        </div>

        <div className="ta-promotion-item">
          <span style={{ top: '120px', left: '300px', fontWeight: 'bold', filter: 'blur(0.3px)' }}>
            Real Madrid wint met 2 goals verchil
          </span>
        </div>
        <div className="ta-promotion-item">
          <span style={{ top: '160px', left: '300px', fontWeight: 'bold', filter: 'blur(0.3px)' }}>
            Karim Benzema scoort 2 of meer goals
          </span>
        </div>
        <div className="ta-promotion-item">
          <span style={{ top: '200px', left: '300px', fontWeight: 'bold', filter: 'blur(0.3px)' }}>
            Mario Gotze scoort
          </span>
        </div>

        <div className="ta-promotion-item">
          <span style={{ top: '120px', left: '665px', background: 'linear-gradient(90deg,#ff914c,#ff328c) 0 0' }}>
            4.00
          </span>
        </div>
        <div className="ta-promotion-item">
          <span style={{ top: '160px', left: '665px', background: 'linear-gradient(90deg,#ff914c,#ff328c) 0 0' }}>
            4.00
          </span>
        </div>
        <div className="ta-promotion-item">
          <span style={{ top: '200px', left: '665px', background: 'linear-gradient(90deg,#ff914c,#ff328c) 0 0' }}>
            4.00
          </span>
        </div>
      </div>
    );
  }
}

export default Banner;
