import { Typography } from '@mui/material';
import type { FC } from 'react';

type SectionTitleProps = {
  title: string;
};

export const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  return (
    <Typography
      variant="h2"
      color="text.secondary"
    >
      {title}
    </Typography>
  );
};
