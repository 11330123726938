import { Button, Card, CardContent, Typography, CardActions, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../router/utils/RouteNames';
import { SectionTitle } from '../components/SectionTitle';
import { useEffect, type CSSProperties } from 'react';
import { useBrandsContext } from './brands/context';

function Home() {
  const { brand } = useBrandsContext();
  useEffect(() => {
    document.title = `${brand.name} | Home`;
  }, [brand.name]);
  return (
    <section style={styles.section}>
      <SectionTitle title="Last used" />
      <Stack
        direction="row"
        spacing={3}
      >
        <Card sx={{ maxWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Banner
            </Typography>
            <Typography
              variant="h5"
              component="div"
            >
              Atletic vs Madrid
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
            >
              adjective
            </Typography>
            <Typography variant="body2">
              well meaning and kindly.
              <br />
              {'"a benevolent smile"'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Banner
            </Typography>
            <Typography
              variant="h5"
              component="div"
            >
              Barcelona vs Madrid
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
            >
              adjective
            </Typography>
            <Typography variant="body2">
              well meaning and kindly.
              <br />
              {'"a benevolent smile"'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      </Stack>
      <SectionTitle title="Suggested" />
      <Stack
        direction="row"
        spacing={3}
      >
        <Card sx={{ maxWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Banner
            </Typography>
            <Typography
              variant="h5"
              component="div"
            >
              Create banner
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
            >
              adjective
            </Typography>
            <Typography variant="body2">
              well meaning and kindly.
              <br />
              {'"a benevolent smile"'}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={RoutePaths.BANNERS}>Create banner</Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Landing Page
            </Typography>
            <Typography
              variant="h5"
              component="div"
            >
              Create landing page
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
            >
              adjective
            </Typography>
            <Typography variant="body2">
              well meaning and kindly.
              <br />
              {'"a benevolent smile"'}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={RoutePaths.LANDING_PAGES}>Create landing page</Link>
          </CardActions>
        </Card>
      </Stack>
    </section>
  );
}

export default Home;

const styles = {
  section: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  } as CSSProperties,
};
