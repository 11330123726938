import { useState, useEffect } from 'react';

import Home from './pages/Home';
import Banners from './pages/Banners';
import NoPage from './pages/NoPage';

import { Casino } from './models';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout, { BrandLayout } from './pages/Layout';
import Media from './pages/Media';
import LandingPagePage from './pages/LandingPagePage';
import LandingPagesPage from './pages/LandingPagesPage';
import { RoutePaths } from './router/utils/RouteNames';
import { DataStore } from 'aws-amplify';
import { BrandsProvider } from './pages/brands/context';
import { BrandsPage } from './pages/brands/view';
import Loading from './components/Loading';
import { QUERY_PARAMS } from './utils/queryParams';

export const LoggedAppProviders = () => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<Casino[]>([]);

  useEffect(() => {
    const casinoSubscription = DataStore.observeQuery(Casino).subscribe(({ items }) => {
      setBrands(items);
      setLoading(false);
    });
    DataStore.start();
    return () => casinoSubscription.unsubscribe();
  }, []);

  return <BrandsProvider brands={brands}>{loading ? <Loading /> : <LoggedApp />}</BrandsProvider>;
};

export const LoggedApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={RoutePaths.ROOT}
          element={<Layout />}
        >
          <Route
            index
            element={<BrandsPage />}
          />
          <Route
            path={`/:${QUERY_PARAMS.brandCode}`}
            element={<BrandLayout />}
          >
            <Route
              index
              element={<Home />}
            />
            <Route
              path={RoutePaths.BANNERS}
              element={<Banners />}
            />
            <Route
              path={`${RoutePaths.LANDING_PAGES}/:lpId`}
              element={<LandingPagePage />}
            />
            <Route
              path={RoutePaths.LANDING_PAGES}
              element={<LandingPagesPage />}
            />
            <Route
              path={RoutePaths.MEDIA}
              element={<Media />}
            />
            <Route
              path="*"
              element={<NoPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
