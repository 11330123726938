import { Storage } from 'aws-amplify';

import { FileManager, Permissions, ItemView } from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { useBrandsContext } from './brands/context';
import type { CSSProperties } from 'react';
import { useEffect, useMemo } from 'react';
import { SectionTitle } from '../components/SectionTitle';

const MEDIA_EXT = ['png', 'gif', 'webp', 'webm', 'jpg', 'jpeg', 'avif', 'svg'];

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

const Media = (props: any) => {
  const { brand } = useBrandsContext();
  useEffect(() => {
    document.title = `${brand.name} | Media Files`;
  }, [brand.name]);

  const fileSystemProvider = useMemo(
    () =>
      new CustomFileSystemProvider({
        getItems: async (directory) => {
          let path = `${brand.code}/${directory.path}`;
          const { results } = await Storage.list(path);
          const items = await Promise.all(results.map((item) => transformMediaItems({ path, item })));
          return items;
        },
        createDirectory: (directory, name) => {
          let path = `${brand.code}/${directory.path}`;

          return Storage.put(`${path + name}/`, '');
        },
        downloadItems: async (items) => {
          await Promise.all(
            items.map(async (item) => {
              let result = await Storage.get(`${brand.code}/${item.path}`, { download: true });
              downloadBlob(result.Body, item.name);
            })
          );
        },
        uploadFileChunk: (file, uploadInfo, destinationDirectory) => {
          let path = `${brand.code}/${destinationDirectory.path}`;

          return Storage.put(path + file.name, file, {
            contentType: file.type, // contentType is optional
          });
        },
        deleteItem(item) {
          console.log(`${brand.code}/${item.path}${item.isDirectory ? '/' : ''}`);
          return Storage.remove(`${brand.code}/${item.path}${item.isDirectory ? '/' : ''}`).then(console.info);
        },
      }),
    [brand.code]
  );

  return (
    <section style={styles.section}>
      <SectionTitle title={`Code: ${brand.code}`} />
      <FileManager
        fileSystemProvider={fileSystemProvider}
        rootFolderName={brand.name}
        width="80vw"
        height="80vh"
      >
        <Permissions
          create={false}
          copy={false}
          move={false}
          delete={true}
          rename={false}
          upload={true}
          download={true}
        />

        <ItemView mode="thumbnails" />
      </FileManager>
    </section>
  );
};

export default Media;

const transformMediaItems = async ({ path, item }: { path: string; item: any }) => {
  if (item.key === undefined) {
    return;
  }

  let itemName = item.key.substring(path.length);
  if (!item || itemName === '/') {
    return;
  }

  let thumbnail: string | undefined = undefined;
  let isDirectory = itemName?.slice(-1) === '/';

  if (isDirectory) {
    itemName = itemName.substring(0, itemName.length - 1);
  } else {
    let extension = itemName.slice(((itemName.lastIndexOf('.') - 1) >>> 0) + 2);

    if (MEDIA_EXT.includes(extension)) {
      thumbnail = await Storage.get(item.key);
    }
  }

  return {
    name: itemName,
    dateModified: item.lastModified,
    thumbnail: thumbnail,
    isDirectory: isDirectory,
    size: item.size,
  };
};

const styles = {
  section: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  } as CSSProperties,
};
