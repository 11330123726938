import type { CSSProperties } from 'react';
import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import QRCodeSVG from 'react-qr-code';

import { Box, Modal, Button, IconButton, Stack, Divider, Skeleton } from '@mui/material';
import DesktopWindowsTwoToneIcon from '@mui/icons-material/DesktopWindows';
import TabletIconTwoToneIcon from '@mui/icons-material/Tablet';
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroid';
import OpenInBrowserTwoToneIcon from '@mui/icons-material/OpenInBrowserTwoTone';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';

import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';

import { Storage } from 'aws-amplify';

import { DataStore } from '@aws-amplify/datastore';
import { LandingPage } from '../models';
import Loading from '../components/Loading';
import PreviewLoadingSpinner from '../components/PreviewLoadingSpinner';
import './LandingPagePage.css';
import { useBrandsContext } from './brands/context';
import { SectionTitle } from '../components/SectionTitle';
import { Spacer } from '../components/Spacer';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '25px',
  boxShadow: 24,
  p: 5,
};

const LandingPagePage = (props: any) => {
  const { lpId } = useParams();
  const { brand } = useBrandsContext();
  useEffect(() => {
    document.title = `${brand.name} | Landing Pages`;
  }, [brand.name]);

  const [loading, setLoading] = useState(true);
  const [qrOpen, setQrOpen] = useState(false);

  const [configSchema, setConfigSchema] = useState<Object>();
  const [uxSchema, setUxSchema] = useState<any>();

  const [lp, setLp] = useState<LandingPage>();

  const previewRef = React.createRef<HTMLIFrameElement>();

  const [qrCodeDisplay, setQrCodeDisplay] = useState<string>('none');
  const [qrCodeValue, setQrCodeValue] = useState<string>('');

  const [data, setData] = useState({});

  let templatesPath = `${brand.code}/lp-templates/`;

  dayjs.extend(utc);
  dayjs.utc();

  useEffect(() => {
    DataStore.query(LandingPage, lpId).then(async (newlp) => {
      setLp(newlp);
      const res = await Storage.get(`${templatesPath + newlp.template}/schema.json`, {
        download: true,
        level: 'protected',
        identityId: 'eu-west-2:6f456f62-991a-4653-96ba-27111826d11c',
      });

      // @ts-ignore
      let schema = await new Response(res.Body).json();
      let defaultData = {};
      Object.keys(schema.config.properties).forEach((key) => {
        if (schema.config.properties[key].default) {
          defaultData[key] = schema.config.properties[key].default;
        }
      });

      setData({
        ...defaultData,
        ...(newlp.config as {}),
      });
      setConfigSchema(schema.config);
      setUxSchema(schema.ux);

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function preview() {
    //@ts-ignore
    document.getElementById('previewId').style = 'display:none;';
    let casino = await lp.casino;
    document.getElementById('preview-block').scrollIntoView();
    document.getElementById('preview-block').classList.add('loading');
    return API.post('apiLP', `/landing-pages/${lpId}/preview`, {
      body: { lp: lpId, name: lp.name, code: lp.code, config: data, brand: casino.code, template: lp.template },
    })
      .then((response) => {
        let path = response.tempPath;
        //@ts-ignore
        document.getElementById('previewId').src = `https://static.cms.cloud.digibeat.com/${path}${
          lp.code
        }.html?i=${Math.floor(Math.random() * 1000000)}`;
        //@ts-ignore
        document.getElementById('previewId').style = 'display:block; height: 120vh';
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function makeDesktop() {
    document.getElementById('previewId').style.width = '100%';
  }

  function makeTablet() {
    document.getElementById('previewId').style.width = '768px';
  }

  function makeMobile() {
    document.getElementById('previewId').style.width = '480px';
  }

  function openTab() {
    //@ts-ignore
    window.open(document.getElementById('previewId').src, '_blank');
  }

  async function generateQr() {
    setQrOpen(true);

    setQrCodeDisplay('none');

    preview().then((_) => {
      setQrCodeDisplay('');
      //@ts-ignore
      setQrCodeValue(document.getElementById('previewId').src);
    });
  }

  async function save() {
    await DataStore.save(
      LandingPage.copyOf(lp, (updated) => {
        updated.config = JSON.stringify(data);
      })
    );
  }

  if (loading) {
    return <Loading />;
  }
  function onLoadFunction() {
    document.getElementById('preview-block').classList.remove('loading');
    document.getElementById('previewId').style.display = 'inline';
  }
  return (
    <section style={styles.section}>
      <SectionTitle title={`${lp.name} - ${brand.name} LP`} />
      <Box
        component="form"
        style={styles.box}
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        padding="20px"
        noValidate
      >
        <JsonForms
          schema={configSchema}
          uischema={uxSchema}
          data={data}
          renderers={materialRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => {
            for (const key in data) {
              if (data[key]) {
                if (data[key] === undefined) {
                  data[key] = null;
                }
              }
            }

            setData(data);
          }}
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={preview}
        >
          Preview
        </Button>
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </Box>

      <div id="preview-block">
        <Stack
          direction="row"
          spacing={1}
          marginTop="25px"
          className="preview-block"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 4,
              width: '100%',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              bgcolor: 'background.paper',
              color: 'text.secondary',
              '& svg': {
                m: 1.5,
              },
              '& hr': {
                mx: 0.5,
              },
            }}
          >
            <SectionTitle title="Preview" />
            <Spacer style={styles.expandedSpacer} />
            <IconButton
              aria-label="desktop"
              onClick={makeDesktop}
            >
              <DesktopWindowsTwoToneIcon />
            </IconButton>
            <IconButton
              aria-label="tablet"
              onClick={makeTablet}
            >
              <TabletIconTwoToneIcon />
            </IconButton>
            <IconButton
              aria-label="mobile"
              onClick={makeMobile}
            >
              <PhoneAndroidTwoToneIcon />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem
            />
            <IconButton
              aria-label="mobile"
              onClick={openTab}
            >
              <OpenInBrowserTwoToneIcon />
            </IconButton>
            <IconButton
              aria-label="mobile"
              onClick={generateQr}
            >
              <QrCode2TwoToneIcon />
            </IconButton>
          </Box>
        </Stack>

        <PreviewLoadingSpinner />

        <iframe
          title={`Preview ${lp.name}`}
          ref={previewRef}
          id="previewId"
          width="100%"
          onLoad={onLoadFunction}
          src={`https://static.cms.cloud.digibeat.com/lps/${lp.code}/${lp.code}.html`}
          style={styles.iframe}
        />
        <Modal
          open={qrOpen}
          onClose={(e) => {
            setQrOpen(false);
          }}
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
              <Skeleton
                variant="rounded"
                width="100%"
                height={300}
                id="qrBoxSkeleton"
                style={{ display: qrCodeDisplay === '' ? 'none' : '' }}
              />

              <QRCodeSVG
                value={qrCodeValue}
                display={qrCodeDisplay}
                style={styles.qrcode}
              />
            </Box>
          </Box>
        </Modal>
      </div>
    </section>
  );
};

export default LandingPagePage;

const styles = {
  section: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  } as CSSProperties,
  expandedSpacer: { flex: 1 } as CSSProperties,
  box: {
    alignSelf: 'center',
    background: 'rgba(0,0,0,0.2)',
    backdropFilter: 'blur(5px)',
    borderRadius: '15px',
    maxWidth: '1000px',
  } as CSSProperties,
  iframe: { height: '120vh' } as CSSProperties,
  qrcode: { alignSelf: 'center' } as CSSProperties,
};
