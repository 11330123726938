import React from 'react';
import './PreviewLoadingSpinner.css';

class PreviewLoadingSpinner extends React.Component {
  render() {
    return (
      <div id="preview-spinner">
        <div className="lds-spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}

export default PreviewLoadingSpinner;
