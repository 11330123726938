import Casino from '@mui/icons-material/Casino';
import { Avatar, Grid } from '@mui/material';
import { useEffect, type CSSProperties } from 'react';
import { useBrandsContext } from './context';
import { NavLink } from 'react-router-dom';
import { SectionTitle } from '../../components/SectionTitle';

export const BrandsPage = () => {
  const { brands } = useBrandsContext();
  useEffect(() => {
    document.title = 'DigitalBeat CMS | Brands';
  }, []);
  return (
    <div style={styles.noBrandSelectedContainer}>
      <SectionTitle title="Select brand first to start working" />
      <Grid
        display="grid"
        justifyContent="center"
        alignItems="center"
        container
        gridAutoColumns="1fr"
        gridAutoRows="1fr"
        gridAutoFlow="column"
        marginTop="10px"
      >
        {brands.map((casino) => (
          <Grid
            key={casino.id}
            item
            xs
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <NavLink
              to={`/${casino.code}/`}
              style={styles.brandItem}
            >
              <Avatar title={casino.name}>
                <Casino />
              </Avatar>
              <div style={styles.brandName}>{casino.name}</div>
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const styles = {
  circularIndicator: { visibility: 'visible' } as CSSProperties,
  noBrandSelectedContainer: { textAlign: 'center', marginTop: '1rem' } as CSSProperties,
  brandItem: {
    background: 'rgba(0,0,0,0.8)',
    padding: '25px',
    borderRadius: '20px',
  } as CSSProperties,
  brandName: { marginLeft: '5px' } as CSSProperties,
};
