import { Typography } from '@mui/material';

function Footer() {
  return (
    <footer>
      <Typography
        variant="h4"
        color="text.secondary"
      >
        DigitalBeat - Internal dev
      </Typography>
    </footer>
  );
}

export default Footer;
