import type { CSSProperties, FC } from 'react';

type SpacerProps = {
  size?: number | string;
  style?: CSSProperties;
};

export const Spacer: FC<SpacerProps> = ({ size = '1rem', style = {} }) => {
  return <div style={{ ...style, ...(size !== null ? { ...styles, height: size, width: size } : {}) }} />;
};

const styles: CSSProperties = {
  display: 'inline-flex',
};
