import type { CSSProperties } from 'react';
import { useMemo, useState } from 'react';
import { Menu, MenuItem, Tooltip, Typography, Divider, MenuList } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { RoutePaths } from './router/utils/RouteNames';
import { useBrandsContext } from './pages/brands/context';
import { useAuthenticator } from '@aws-amplify/ui-react';
import type { LazyCasino } from './models';

const NavBar = () => {
  const { user, signOut } = useAuthenticator();
  const navigate = useNavigate();
  const { brandCode } = useParams();
  const { brands, brand } = useBrandsContext();
  const code = useMemo(() => brand?.code, [brand]);
  const [anchorElChangeBrand, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenChangeBrandMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseChangeBrandMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getActiveClass = ({ isActive }) => `item ${isActive ? 'active' : ''}`;
  const handleSelectCasino = (casino: LazyCasino) => {
    const path = brandCode ? window.location.pathname.replace(brandCode, casino.code) : `/${casino.code}/`;
    handleCloseChangeBrandMenu();
    navigate(path);
  };

  return (
    <nav>
      {brand && (
        <header>
          <div
            className="content"
            style={styles.navigationContainer}
          >
            <div
              className="list"
              style={styles.list}
            >
              <NavLink
                to={RoutePaths.ROOT}
                className={getActiveClass}
              >
                <img
                  className="logo"
                  style={styles.logo}
                  src="https://digibeat.com/wp-content/uploads/2022/07/digibeat-favicon.png"
                  alt="logo"
                />
              </NavLink>
              <NavLink
                to={`${code}/`}
                className={getActiveClass}
              >
                Home
              </NavLink>
              <NavLink
                to={`${code}/${RoutePaths.BANNERS}`}
                className={getActiveClass}
              >
                Banners
              </NavLink>
              <NavLink
                to={`${code}/${RoutePaths.LANDING_PAGES}`}
                className={getActiveClass}
              >
                Landing Pages
              </NavLink>
              <NavLink
                to={`${code}/${RoutePaths.MEDIA}`}
                className={getActiveClass}
              >
                Media Files
              </NavLink>
            </div>
          </div>
        </header>
      )}
      <div className="profile">
        <Tooltip title="Change brand">
          <div onClick={handleOpenChangeBrandMenu}>{brand?.name || 'Select brand'} ▼</div>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElChangeBrand}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElChangeBrand)}
          onClose={handleCloseChangeBrandMenu}
        >
          <MenuList>
            {brands.map((casino) => (
              <MenuItem
                key={casino.id}
                onClick={(e) => {
                  handleSelectCasino(casino);
                }}
              >
                {casino.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        <div style={styles.verticalDivider}>|</div>
        <Tooltip title="Open settings">
          <div onClick={handleOpenUserMenu}>{user?.attributes?.email}</div>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Divider />
          <MenuItem onClick={signOut}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
};

export default NavBar;

const styles = {
  navigationContainer: {
    borderRadius: '0 0 20px 20px',
    // border: 'solid 1px rgba(88, 92, 98, 0.5)',
    overflow: 'hidden',
  } as CSSProperties,
  list: {
    display: 'flex',
    WebkitBoxPack: 'justify',
    msflexPack: 'justify',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    userSelect: 'none',
  } as CSSProperties,
  logo: {
    height: 34,
    resizeMode: 'contain',
  } as CSSProperties,
  verticalDivider: { margin: '0 10px' } as CSSProperties,
};
