import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { Casino } from '../../models';

interface IBrandContext {
  brands: Casino[];
  brand: Casino;
  changeBrand: (brand: Casino) => void;
}

const BrandContext = createContext<IBrandContext>({} as IBrandContext);

type Props = PropsWithChildren & {
  brands: Casino[];
};

export const BrandsProvider: FC<Props> = ({ children, brands }) => {
  const [brand, setBrand] = useState<Casino>(null);

  const changeBrand = useCallback((brand: Casino) => {
    setBrand(brand);
  }, []);

  const value = useMemo(() => ({ brands, brand, changeBrand }), [brands, brand, changeBrand]);

  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};

export const useBrandsContext = () => useContext(BrandContext);
