import React from 'react';
import './DownloadLoadingSpinner.css';

class DownloadLoadingSpinner extends React.Component {
  render() {
    return (
      <div id="download-spinner">
        <div className="Message Message-wrapper">
          <div className="Message-icon">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          <div className="Message-body">
            <p>Your Landing Page download is being prepared. Please wait!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadLoadingSpinner;
